import * as React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'

const NotFoundPage = () => (
  <IndexLayout>
    <Page>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fast Quote For Software Development</title>
        <link rel="canonical" href="http://kematixdigital.com/contact-us" />
      </Helmet>
      <Container>
        <h1>404: Page not found.</h1>
        <p>
          You've hit the void. <Link to="/">Go back home.</Link>
        </p>
      </Container>
    </Page>
  </IndexLayout>
)

export default NotFoundPage
